.chat-body-container-outter{
    height: 80svh;
  }
  
  .chat-body-container .chat-messages-container{
    top: 80px;
    height: calc( 100% - 150px );
  }
  
  
  .chat-body-container .chat-messages-container::-webkit-scrollbar {
    width: 0.25rem;
  }
  
  .chat-body-container .chat-messages-container::-webkit-scrollbar-track {
    background: rgb(231, 40, 95, 0.5);
  }
  
  .chat-body-container .chat-messages-container::-webkit-scrollbar-thumb {
    background: rgb(231, 40, 95)
  }

@media (max-width: 767px) {
    .chat-body-container-outter{
      height: calc( 100svh - 56px );
    }

    .chat-screen{
        overflow: hidden;
        height: 100svh;
    }

    .chat-body-container .chat-messages-container{
      height: calc( 100% - 215px );
    }
}