.rbc-button-link {
  font-size: 1.2rem;
  width: 100%;
}

.rbc-header {
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.rbc-today {
  font-weight: bold;
  background-color: white;
}

.rbc-time-gutter>.rbc-timeslot-group {
  border: none;
}

.rbc-time-header-gutter {
  border: none;
}

.rbc-time-gutter {
  margin-right: 1rem;
}

.rbc-time-view {
  border-top: none;
  border-left: none;
}

.rbc-time-content {
  border-top: none;
}

.rbc-allday-cell {
  display: none;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  /* gradient background from ff2d55 */
  background: #ff2d55;
  background: linear-gradient(90deg, rgba(255, 45, 85, 1) 0%, rgba(254, 71, 106, 1) 35%, rgba(255, 86, 119, 1) 100%);
  border: none;
  font-size: 0.9rem;
}

.custom-header-event{
  background: linear-gradient(90deg, rgba(255, 45, 85, 1) 0%, rgba(254, 71, 106, 1) 35%, rgba(255, 86, 119, 1) 100%);
  border: none;
  font-size: 0.9rem;
  color: white;
  border-radius: 4px;
  padding: 0.3rem;

}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-time-header-gutter {
  margin-right: 1rem;
}

.rbc-background-event {
  height: auto !important;
  padding: 5px !important;
}

.rbc-background-event>.rbc-event-label {

}