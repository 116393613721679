// FONT FACE --INTER
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../fonts/Inter/static/Inter-Light.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}

// Catamaran
@font-face {
  font-family: Catamaran;
  font-weight: 300;
  src: url("../fonts/Catamaran/Catamaran-Light.ttf");
}
@font-face {
  font-family: Catamaran;
  font-weight: 400;
  src: url("../fonts/Catamaran/Catamaran-Regular.ttf");
}
@font-face {
  font-family: Catamaran;
  font-weight: 500;
  src: url("../fonts/Catamaran/Catamaran-Medium.ttf");
}
@font-face {
  font-family: Catamaran;
  font-weight: 600;
  src: url("../fonts/Catamaran/Catamaran-SemiBold.ttf");
}
@font-face {
  font-family: Catamaran;
  font-weight: 700;
  src: url("../fonts/Catamaran/Catamaran-Bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Catamaran;
  --font-body: Catamaran;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
