.rbc-button-link {
  font-size: 1.2rem;
  font-weight: 400;
}
.rbc-header {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.rbc-today {
  font-weight: bold;
  background-color: white;
}
.rbc-time-gutter > .rbc-timeslot-group {
  border: none;
}
.rbc-time-header-gutter {
  border: none;
}
.rbc-time-gutter {
  margin-right: 1rem;
}
.rbc-time-view {
  border-top: none;
  border-left: none;
}
.rbc-time-content {
  border-top: none;
}
.rbc-allday-cell{
  display: none;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  /* gradient background from ff2d55 */
  background: #ff2d55;
  background: linear-gradient(90deg, rgba(255,45,85,1) 0%, rgba(254,71,106,1) 35%, rgba(255,86,119,1) 100%);
  border: none;
  font-size: 0.9rem;
}
.rbc-day-slot .rbc-time-slot{
  border: none;
} .rbc-time-header-gutter{
  margin-right: 1rem;
}

.rbc-timeslot-group{
  border-left: 1px solid #E5E7EB;
  border-bottom: 1px solid #E5E7EB;

}

.rbc-label {
  font-size: 0.9rem;
}